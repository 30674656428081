import React from 'react';
import { navigate } from 'gatsby-link'

const API_KEY = process.env.REACT_APP_MG_API_KEY
const DOMAIN = process.env.REACT_APP_MG_DOMAIN

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY});


let i = 0
function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}


class EventForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    //console.log(e.target.name,e.target.value)
    this.setState({ [e.target.name]: e.target.value })
  }


  handleSubmit = e => {

    //disableEmptyInputs();
    e.preventDefault()
    const form = e.target;


    let formdata
    formdata = encode({
     'form-name': form.getAttribute('name'),
     ...this.state,
   })


      for (let value of formdata.values()) {
         console.log(value);
      }
      console.log(document.getElementById('adres email event').value)

      fetch('/', {
        method: 'POST',
        body: formdata,
      })
        .then(() => {

          const messageData = {
            from: 'Art Open <biuro@artopen.pl>',
            to: document.getElementById('adres email event').value,
            subject: 'Thank you for registering for the Cleanup Action vol.3 event',
            html: '<p style="text-align:center;display:block;margin:auto;max-width:100%"><img src="https://artopen.co/img/Autoresponder_header.png" style="min-width:300px;max-width:90%" alt="Cleaning Action vol.III." /></p><br><p>Hello, thank you very much for registering for our <b style="color:#23C495">Cleaning Action vol.III.</b>!</p><br><span style="color:white;background-color:#23C495;border-radius:15px;padding:10px 20px;"> Start <b>May 27, 2022 - 9:00 a.m.</b></span><br><br><p> Caring for our planet and shaping ecological attitudes is very important in the times of enormous climate change.<br>We are glad that together, we will be able to take care of a piece of common space and clean it up. It`s important to start with yourself, setting an example for others! </p><br><p>Ask your friends to join and invite them on our behalf!</p><br> See how we cleaned last year: <br><a  style="color:#23C495" target="_blank" href="https://opengraf.pl/cdn/Art_Open_Akcja_Sprzatanie_2.mp4">Watch the movie</a> | <a style="color:#23C495" target="_blank" href="https://artopen.co/blog/cleaning-action-open-for-environment-v-2/">Read the entry</a>.<p><br><b style="color:#23C495" >See you soon! :)</b></p>'
          };
          console.log(client)

          client.messages.create(DOMAIN, messageData)
           .then((res) => {
             console.log(res);
           })
           .catch((err) => {
             console.error(err);
           });


          navigate('/contact/sukces/')
        })
        .catch(error => alert(error))


}



 addPerson = () => {
      if (i < document.getElementsByClassName('added').length){
      const mediaQuery = window.matchMedia('(max-width: 982px)')
      console.log(mediaQuery)
      if (mediaQuery.matches == false){
      document.getElementsByClassName('added')[i].style.display = 'flex'
    } else {
      document.getElementsByClassName('added')[i].style.display = 'block'
    }
      i++;
    } else {
      i = i;
    }
      console.log(i)


}

 hideBox = (event) => {
  event.target.parentNode.parentNode.style.display = 'none'
  i--;
  console.log(i)
}


render() {
    return (
      <>
      <div id="Event">
      <form
        name="Akcja sprzątanie"
        id = "event"
        method="post"
        data-netlify="true"
        action="/contact/sukces/"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        style={{backgroundColor:'#23C495',color:'white',padding: '2%',borderRadius:'20px'}}

      >
      <input name='Akcja Sprzątanie' id='event' type="hidden" className='subtitle'  />
      <br />
      <h3 className="stays-white" style={{textAlign:'center',fontSize:'2em',fontWeight:'900',fontVariant:'all-small-caps',fontFamily:'system-ui'}}>Sign up for the event</h3>
      <hr style={{marginLeft:'30px',marginRight:'30px'}} />

      <div className="field column" style={{marginLeft:'15px',marginRight:'15px'}}>
      <div className="field">
        <label className="label" htmlFor={'firma'}>
          Company<sup>*</sup>:
        </label>
        <div className="control">
          <input
            className="input"
            type={'text'}
            name={'firma'}
            onChange={this.handleChange}
            id={'firma'}
            style={{maxWidth:'calc(100% - 33% - 100px)'}}
            required={true}
          />
        </div>
        </div>

        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Sprzątanie - event" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field"
            onChange={this.handleChange}
            />
          </label>
        </div>

      <div className='columns'>
          <div className="field column">
            <label className="label" htmlFor={'name'}>
              Name and Surname<sup>*</sup>
            </label>
            <div className="control">
              <input
                className="input"
                type={'text'}
                name={'imię i nazwisko'}
                onChange={this.handleChange}
                id={'imię i nazwisko event'}
                required={true}
              />
            </div>
            </div>

            <div className="field column">
              <label className="label" htmlFor={'email'}>
                E-mail<sup>*</sup>
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'email'}
                  name={'email'}
                  onChange={this.handleChange}
                  id={'adres email event'}
                  required={true}
                />
              </div>
            </div>

            <div className="field column">
              <label className="label" htmlFor={'email'}>
                Phone number<sup>*</sup>
              </label>
              <div className="control">
                <input
                  className="input"
                  type={'tel'}
                  name={'telefon'}
                  onChange={this.handleChange}
                  id={'telefon-event'}
                  required={true}
                />
              </div>
            </div>

            <div className="field column" style={{maxWidth:'min-content',minWidth:'100px'}}>
            <label className="label hidelblmob" style={{visibility:'hidden'}}>
            Add a person
            </label>
            <div className="control">
            <a onClick={this.addPerson} className="input button is-primary" style={{minHeight:'40px',marginTop:'3px',border:'1px solid white',fontSize:'12px'}}>+ add a person</a>
            </div>
            </div>
          </div>







          <div className='columns added' style={{display:'none'}}>
              <div className="field column">
                <label className="label" htmlFor={'imię i nazwisko event1'}>
                  Name and Surname
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'imię I:'}
                    onChange={this.handleChange}
                    id={'imię i nazwisko event1'}
                    required={false}
                  />
                </div>
                </div>

                <div className="field column">
                  <label className="label" htmlFor={'adres email event1'}>
                    E-mail
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email1'}
                      name={'email I:'}
                      onChange={this.handleChange}
                      id={'adres email event1'}
                      required={false}
                    />
                  </div>
                </div>

                <div className="field column">
                  <label className="label" htmlFor={'telefon-event1'}>
                    Phone number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'tel1'}
                      name={'telefon I:'}
                      onChange={this.handleChange}
                      id={'telefon-event1'}
                      required={false}
                    />
                  </div>
                </div>
                <div className="control" style={{paddingTop:'8px',textAlign:'center'}}>
                <label className="label hidelblmob" style={{visibility:'hidden'}}>
                  minus<sup>*</sup>
                </label>
                <a onClick={this.hideBox} className="input button is-primary" style={{minHeight:'40px',marginTop:'3px',border:'1px solid white',fontSize:'12px',maxWidth:'100px'}}>-</a>
                </div>
              </div>








              <div className='columns added' style={{display:'none'}}>
                  <div className="field column">
                    <label className="label" htmlFor={'imię i nazwisko event2'}>
                      Name and Surname
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'imię II:'}
                        onChange={this.handleChange}
                        id={'imię i nazwisko event2'}
                        required={false}
                      />
                    </div>
                    </div>

                    <div className="field column">
                      <label className="label" htmlFor={'adres email event2'}>
                        E-mail
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email2'}
                          name={'email II:'}
                          onChange={this.handleChange}
                          id={'adres email event2'}
                          required={false}
                        />
                      </div>
                    </div>

                    <div className="field column">
                      <label className="label" htmlFor={'telefon-event2'}>
                        Phone number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'tel'}
                          name={'telefon II:'}
                          onChange={this.handleChange}
                          id={'telefon-event2'}
                          required={false}
                        />
                      </div>
                    </div>
                    <div className="control" style={{paddingTop:'8px',textAlign:'center'}}>
                    <label className="label hidelblmob" style={{visibility:'hidden'}}>
                      minus<sup>*</sup>
                    </label>
                    <a onClick={this.hideBox} className="input button is-primary" style={{minHeight:'40px',marginTop:'3px',border:'1px solid white',fontSize:'12px',maxWidth:'100px'}}>-</a>
                    </div>
                  </div>



                  <div className='columns added' style={{display:'none'}}>
                      <div className="field column">
                        <label className="label" htmlFor={'imię i nazwisko event3'}>
                          Name and Surname
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={'text'}
                            name={'imię III:'}
                            onChange={this.handleChange}
                            id={'imię i nazwisko event3'}
                            required={false}
                          />
                        </div>
                        </div>

                        <div className="field column">
                          <label className="label" htmlFor={'adres email event3'}>
                            E-mail
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'email'}
                              name={'email III:'}
                              onChange={this.handleChange}
                              id={'adres email event3'}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className="field column">
                          <label className="label" htmlFor={'telefon-event3'}>
                            Phone number
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'tel'}
                              name={'telefon III:'}
                              onChange={this.handleChange}
                              id={'telefon-event3'}
                              required={false}
                            />
                          </div>
                        </div>
                        <div className="control" style={{paddingTop:'8px',textAlign:'center'}}>
                        <label className="label hidelblmob" style={{visibility:'hidden'}}>
                          minus<sup>*</sup>
                        </label>
                        <a onClick={this.hideBox} className="input button is-primary" style={{minHeight:'40px',marginTop:'3px',border:'1px solid white',fontSize:'12px',maxWidth:'100px'}}>-</a>
                        </div>
                      </div>

                      <div className='columns added' style={{display:'none'}}>
                          <div className="field column">
                            <label className="label" htmlFor={'imię i nazwisko event4'}>
                              Name and Surname
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={'text'}
                                name={'imię IV:'}
                                onChange={this.handleChange}
                                id={'imię i nazwisko event4'}
                                required={false}
                              />
                            </div>
                            </div>

                            <div className="field column">
                              <label className="label" htmlFor={'adres email event4'}>
                                E-mail
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type={'email'}
                                  name={'email IV:'}
                                  onChange={this.handleChange}
                                  id={'adres email event4'}
                                  required={false}
                                />
                              </div>
                            </div>

                            <div className="field column">
                              <label className="label" htmlFor={'telefon-event5'}>
                                Phone number
                              </label>
                              <div className="control">
                                <input
                                  className="input"
                                  type={'tel'}
                                  name={'telefon IV:'}
                                  onChange={this.handleChange}
                                  id={'telefon-event5'}
                                  required={false}
                                />
                              </div>
                            </div>
                            <div className="control" style={{paddingTop:'8px',textAlign:'center'}}>
                            <label className="label hidelblmob" style={{visibility:'hidden'}}>
                              minus<sup>*</sup>
                            </label>
                            <a onClick={this.hideBox} className="input button is-primary" style={{minHeight:'40px',marginTop:'3px',border:'1px solid white',fontSize:'12px',maxWidth:'100px'}}>-</a>
                            </div>
                          </div>

          <hr />
          <div className="column">
          <div className="control">
          <label className='main' style={{paddingBottom:'0px',marginTop:'-15px',marginBottom:'0px',fontSize:'12px'}}  htmlFor="privacy-event">   <input required={true} onChange={this.handleChange} type="checkbox" id="privacy-event" name="privacy" value="true"/>I agree to the processing of my personal data in accordance with the <a target="_blank" rel="noopener noreferrer" href="/privacy-policy/">privacy policy</a><sup>*</sup>.<span className="check"></span></label><br />
          <label className='main' style={{paddingBottom:'0px',marginTop:'-15px',marginBottom:'0px',fontSize:'12px'}}  htmlFor="privacy-eventII">   <input required={true} onChange={this.handleChange} type="checkbox" id="privacy-eventII" name="privacyI" value="true"/>I agree to put the company's logo in the marketing materials promoting the Cleaning Action.<span className="check"></span></label><br />
          <label className='main' style={{paddingBottom:'0px',marginTop:'-15px',marginBottom:'30px',fontSize:'12px'}}  htmlFor="privacy-eventIII">   <input required={true} onChange={this.handleChange} type="checkbox" id="privacy-eventIII" name="privacyII" value="true"/>I agree to the use of my image for the purposes of promoting the event. Expressing consent is equal to agreement that photos, videos or recordings made during the cleaning action may be uploaded on the artopen.pl website, in promotional materials and published in social media. <span className="check"></span></label>
          </div>
          <div className="field" style={{textAlign:'center'}}>
            <button className="button is-primary" style={{border:'1px solid white'}} type="submit" onSubmit={this.handleSubmit}>
              Send
            </button>
          </div>
          </div>
          </div>
      </form>
      </div>



      </>
    )
  }
}



export default EventForm;
