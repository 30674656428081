import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import EventForm from '../components/forms/EventForm'
import config from '../../config'
import Layout from '../components/Layout'

const AkcjaSprzatanie = () => (
  <>

  <Layout>
  <Helmet
    htmlAttributes={{
      lang: `en-en`,
    }}

    title='Clean up action - Open to the environment - vol.III'
    meta={[
      {
        name: `description`,
        content: config.siteDescription,
      },
      {
        name: `viewport`,
        content: `width=device-width, initial-scale=1`,
      },
      {
        name: `msapplication-TileColor`,
        content: config.themeColor,
      },
      {
        name: `theme-color`,
        content: config.themeColor,
      },
    ]}
    link={[
      {
        rel: `apple-touch-icon`,
        href: `/icons/apple-touch-icon.png`,
        sizes: `180x180`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-32x32.png`,
        sizes: `32x32`,
      },
      {
        rel: `icon`,
        type: `image/png`,
        href: `/favicon-16x16.png`,
        sizes: `16x16`,
      },
      {
        rel: `mask-icon`,
        href: `/icons/safari-pinned-tab.svg`,
        color: config.themeColor,
      },
      {
        rel: `preconnect`,
        href: `https://fonts.gstatic.com`,
      },
      {
        rel: `stylesheet`,
        href: `https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap`,
      },
    ]}
  > </Helmet>
  <div style={{marginLeft:'20px',marginBottom:'-50px'}}>
  <br />
  <br />
  <img src="/img/ArtOpen-green.svg" alt="Art Open advertising agency" style={{maxWidth:'300px'}} />
  </div>
  <div id="sprzatanie" style={{height:'85%',width:'100%',backgroundImage:'url(/img/akcja-sprzatanie-3.svg)',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',fontFamily:'Poppins, sans-serif !important'}}>
  </div>

  <div id="action" style={{textAlign:'center',color:'#23C495',fontSize:'3.5em',width:'50%',marginLeft:'25%',fontVariant:'all-petite-caps',letterSpacing: '0.3em'}}>
  <h1 style={{fontWeight:'900',fontVariant:'all-small-caps',fontFamily:'system-ui',wordBreak:'break-word'}}>Clean up action</h1>
  <h2 style={{fontWeight:'400',marginTop:'-20px',fontFamily:'system-ui',fontVariant:'all-small-caps',fontSize:'.8em',wordBreak:'break-word'}}>Open to the environment</h2>
  <hr style={{backgroundColor:'#23C495',marginTop:'0px'}} />
  <h3 style={{fontWeight:'900',marginTop:'-20px',fontVariant:'contextual',fontFamily:'system-ui'}}> vol. III </h3>
  </div>

  <div style={{paddingTop:'100px',paddingLeft:'10%',paddingRight:'10%',textAlign:'left'}}>
  <h2 style={{color:'#23C495',textAlign:'center'}} className='title'>
  <img src="/img/kontakt-center.svg" alt="Registration form - clean up action" width="100px" />
  <br />
  Registration form - Clean up action 2022
  </h2>
  <br />
  <h3 style={{textAlign:'center',marginTop:'20px',fontSize:'.9em'}}>Register your participation in the event <br/>
  <sub>Registration continues until <b>23 May</b>.</sub>
  </h3>


  <div id="sprzatanie-form" style={{padding:'2% 10% 5% 10%'}}>
  <EventForm />
  <br />
  {/*<sub>W związku z panującą pandemią i obostrzeniami z nią związanymi, pamiętaj, aby zarejestrować konkretną ilość osób z Twojej firmy, biorących udział w evencie – dzięki temu będziemy w stanie oszacować liczbę uczestników, zagwarantować bezpieczeństwo i… piękne, imienne identyfikatory dla wszystkich.</sub>*/}

  <br />
  <h2 className='title' style={{marginTop:'50px'}}>
  <b style={{color:'#23C495'}}>Art Open - Open to the environment</b>, let's clean up Wrocław together!
  </h2>
  <p>As an advertising agency focused on specific activities supporting environmental protection,
  we would like to invite you for the third time to an event of joint cleaning of the Odra embankment.</p>
  <hr style={{backgroundColor:'#23C495'}} />

  <br />
  <h4 className="title" style={{color:'#23C495'}}><b>When?</b></h4>
  <p>&bull; 27 May - Friday - 9:00</p>
  <br />

  <h4 className="title" style={{color:'#23C495'}}><b>Where?</b></h4>
  <p>&bull; In the third edition of the event, we will be cleaning up the banks of the Oder near the Kozanow embankment.</p>
  <br />

  <h4 className="title" style={{color:'#23C495'}}><b>What to take?</b></h4>
  <div><ul>
  <li>&bull; Comfortable outdoor clothing appropriate to the weather - preferably longer trousers</li>
  <li>&bull; Refreshing/disinfecting wipes</li>
  <li>&bull; Eagerness to work</li>
  <li>&bull; Lots of smiles and enthusiasm</li>
  </ul></div>
  <br />
  <h4 className="title" style={{color:'#23C495'}}><b>How do we clean?</b></h4>
  <p>
  We meet at 9:00 am at the Hot-Spot beach, register participants and get going!<br />
  Among other things, you will receive a cleaning essential in your starter packs:
  <br /><br />
  <ul>
  <li>&bull; Rubbish bags</li>
  <li>&bull; Protective gloves</li>
  <li>&bull; Water</li>
  </ul>  <br />
  We also provide designated sanitary areas with toilet and washbasin on site.
  <br />
  Integration and refreshments will take place after the clean up - as every year we invite you to the town beach Hot Spot on Wejherowska Street!
  <br />
  The entire clean up will be recorded and those taking part will receive an edited video of the entire initiative.
  </p>
  <br />
  <h4 className="title" style={{color:'#23C495'}}><b>Contest?</b></h4>
  <p>The previous edition of the Clean Up Action went down in history not only because of the two containers full of rubbish we collected during the event. We also organized a competition during which the participants had the opportunity to demonstrate their cleverness, creativity, but also hard work and we’re proud to say that everyone liked it!</p>
  <br />
  <p>●	Therefore, in the third edition of our Clean Up Action, we will organize a similar event. The regulations and details of the competition can be found in the link below:<br /><br /></p>
  <p style={{textAlign:'center'}}><Link className="button-green" to='/cleaning-action-rules/' style={{fontVariant:'all-small-caps',fontSize:'16px'}}> Contest Rules</Link></p><br />
  <br />
  <br />
  <h4 className="title" style={{color:'#23C495'}}><b>To <span style={{color:'#0057b8'}}>help</span> <span style={{color:'#ffd700'}}>Ukraine</span></b></h4>
  <p>A fundraising event will be held during the clean up to support Ukraine.<br />
  The action will be carried out by the group <b>HELP - Heroiczna Ekipa Lokalnej Pomocy</b>.
  <br /><br />
  <li className="column" style={{padding:'5%',maxWidth:'350px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}>  <a className="inline-link" target="_blank" rel="noopener noreferrer" href='https://en-en.facebook.com/helpolawa/'><img alt="help" src="/img/logo-partners/logo-help.svg" style={{minWidth:'100px',maxWidth:'350px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  </p>
  <br />
  <h4 className="title" style={{color:'#23C495'}}><b>Still hesitating?</b></h4>
  <p><Link className="inline-link subtitle" to='/blog/cleaning-action-open-for-environment-v-2/'>See how great our clean up went last year!</Link>
  </p>
  {/* VIDEO*/}
  <br />
  <div className="columns vids" style={{width:'100%',textAlign:'center',display:'flex',}}>
    <video className="column" style={{width:'40%',borderRadius:'20px'}} loading="lazy" src="https://opengraf.pl/cdn/Art_Open_Akcja_Sprzatanie_2.mp4" poster="/img/Screen_01_Akcja.jpg" controls async></video>
    <video className="column" style={{width:'40%',borderRadius:'20px'}} loading="lazy" src="https://opengraf.pl/cdn/Wywiady_mniejsze.mp4" poster="/img/Screen_01_Wywiady.jpg" controls async></video>
  </div>


  <div className="column" style={{paddingTop:'10px',textAlign:'center'}}>
  <hr style={{backgroundColor:'#23C495'}} />
  <h3 style={{fontVariant:'all-petite-caps',fontSize:'3em',letterSpacing: '0.3em',marginTop:'-15px'}}> event plan</h3>
  <br /><br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>9:00</b><br /> Gathering + issuing starters with essentials </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>10:30</b><br /> Official opening </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>11:00</b><br /> Start of the clean up </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>14:30</b><br /> End of the clean up </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>15:00</b><br /> Start after party at Hot Spot with refreshments </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>15:30</b><br /> Contest results </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>17:00</b><br /> Closing of the official part of the event </p>
  <br />
  <p><img alt="check" src="/img/covid-icons/tick.svg" width="20px"/><br />
  <b style={{color:'#23C495'}}>19:00</b><br /> HVZX concert </p>
  <br />
  <hr style={{backgroundColor:'#23C495'}} />
  <h2 className="title" style={{textAlign:'center',color:'#23C495',fontVariant:'all-petite-caps'}}>Action partners</h2>
  </div>

  <div>
  <ul className="columns" style={{textAlign:'left',marginTop:'25px',alignItems:'center'}}>
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/dystrybutoryskywater" className="inline-link" target="_blank"><img alt="Skywater" src="/img/logo-partners/skywater.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/Gnejsy/" className="inline-link" target="_blank"><img alt="Gnejsy" src="/img/logo-partners/gnejsy.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/AgroFunka" className="inline-link" target="_blank"><img alt="Agrofunka" src="/img/logo-partners/agrofunka.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/B8GROUPPOLSKA" className="inline-link" target="_blank"><img alt="B8Group" src="/img/logo-partners/b8_group.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  </ul>

  <ul className="columns" style={{textAlign:'left',marginTop:'25px',alignItems:'center'}}>
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/DeDietrichPL" className="inline-link" target="_blank"><img alt="De Dietrich" src="/img/logo-partners/de_dietrich.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><img alt="ST" src="/img/logo-partners/service_technical.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.kermi.pl/" className="inline-link" target="_blank"><img alt="m-toilet" src="/img/logo-partners/kermi.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/pickupburgerwro" className="inline-link" target="_blank"><img alt="pan precel" src="/img/logo-partners/pick_up_burger.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  </ul>

  <ul className="columns" style={{textAlign:'left',marginTop:'25px',alignItems:'center'}}>
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/dlaprzemyslu" className="inline-link" target="_blank"><img alt="Dla Przemysłu" src="/img/logo-partners/dla_przemyslu.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/PKBWroclaw" className="inline-link" target="_blank"><img alt="hot spot" src="/img/logo-partners/pkb_wroclaw.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/ekosystem.wroc/" className="inline-link" target="_blank"><img alt="ecosystem" src="/img/logo-partners/ekosystem.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/mToiIet/" className="inline-link" target="_blank"><img alt="m-toilet" src="/img/logo-partners/mtoilet.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  </ul>

  <ul className="columns" style={{textAlign:'left',marginTop:'25px',alignItems:'center'}}>
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/panprecel/" className="inline-link" target="_blank"><img alt="pan precel" src="/img/logo-partners/pan_precel.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/HotSpotBeachBar/" className="inline-link" target="_blank"><img alt="hot spot" src="/img/logo-partners/hot_spot.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.chemekosystem.pl" className="inline-link" target="_blank"><img alt="chemeko" src="/img/logo-partners/chemeko-system.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  <li className="column" style={{padding:'5%',maxWidth:'300px',display:'block',margin:'auto',borderRadius:'10px',backgroundColor:'white'}}><a href="https://www.facebook.com/enelmed" className="inline-link" target="_blank"><img alt="enelmed" src="/img/logo-partners/enel-med.png" style={{minWidth:'50px',maxWidth:'300px'}} width="100%"/></a></li>&nbsp;&nbsp;&nbsp;
  </ul>
  </div>

  <div className="column" style={{textAlign:'center'}}>
  <br />
  <h3 style={{textAlign:'center'}}>Would you like to support the project? <Link className="inline-link" to="/contact/">Contact us and join our partners!</Link></h3>
  <hr style={{backgroundColor:'#23C495'}} />
  <h3 style={{fontVariant:'all-petite-caps',fontFamily:'system-ui',fontSize:'1.7em',letterSpacing: '0.3em',marginTop:'-15px'}}>LET'S CLEAN UP WROCLAW TOGETHER!<br />
  <b style={{color:'#23C495'}}>See you there!</b></h3>
  </div>





  </div>






  </div>







  <div style={{textAlign:'center',paddingBottom:'50px'}}>
    <Link className='button-green' to="/"> return to home page </Link>
  </div>
  <br />


  </Layout>
  </>

)


export default AkcjaSprzatanie
